import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import axiosConfigThirdParty from "./../axiosConfitthirdparty";

import { Col, Input, Row, Spinner, Table, Button } from "reactstrap";
import axios from "axios";
import { SavedData } from "../EndPoint/EndPoint";

function Logs() {
  const [List, setList] = useState([]);
  const [AllList, setAllList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    axiosConfigThirdParty
      .get(`${SavedData}/${userData?.database}`)
      .then((res) => {
        debugger;
        if (res?.data?.Attendance?.length > 0) {
          let value = res?.data?.Attendance?.reverse();
          setList(value);
          setAllList(value);
        }
      })
      .catch((err) => {
        console.log(err);
        setList([]);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        <Row>
          <Col>
            <div className="mt-1 mb-1">
              <h2>Attendance Logs</h2>
            </div>
          </Col>
          <Col></Col>
          <Col lg="2" md="2" sm="6" xs="6">
            <div className="mt-1 mb-1 d-flex justify-content-end">
              <Input
                onChange={(e) => {
                  let value = e.target.value;
                  let filterdata;
                  if (value?.length > 0) {
                    filterdata = AllList?.filter((ele) =>
                      ele?.date?.includes(value)
                    );
                    if (filterdata?.length > 0) {
                      setList(filterdata);
                    } else {
                      setList(AllList);
                    }
                  } else {
                    setList(AllList);
                  }
                }}
                placeholder="Search by Date..."
                type="text"
              />
            </div>
          </Col>
          <Col>
            <div className="mt-1 mb-1 d-flex justify-content-end">
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/attendancelist");
                }}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <Table hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {List?.length > 0 ? (
              <>
                {List?.map((ele, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {" "}
                      <img
                        style={{ borderRadius: "12px" }}
                        width="150px"
                        height={100}
                        src={ele?.image}
                        alt=""
                      />{" "}
                    </td>
                    <td>{ele?.currentDate}</td>
                    <td>{ele?.time}</td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Logs;
