import React, { useEffect, useState } from "react";

const officeTimings = [
  {
    id: "2",
    shift: "Morning",
    status: "0",
    sup_id: "1",
    from_time: "10:00:30",
    from_late: "10:40:00",
    to_time: "19:00:00",
    to_late: "19:25:00",
  },
  {
    id: "3",
    shift: "Evening",
    status: "0",
    sup_id: "1",
    from_time: "05:00:00",
    from_late: "05:35:00",
    to_time: "17:00:00",
    to_late: "17:10:00",
  },
  {
    id: "4",
    shift: "Night",
    status: "0",
    sup_id: "1",
    from_time: "23:30:25",
    from_late: "00:40:25",
    to_time: "06:00:00",
    to_late: "06:40:25",
  },
];

const isTimeWithinRange = (time, startTime, endTime) => {
  const parseTime = (t) => {
    const [hours, minutes, seconds] = t.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  const timeToCheck = parseTime(time);
  const start = parseTime(startTime);
  const end = parseTime(endTime);

  if (end < start) {
    return timeToCheck >= start || timeToCheck <= end;
  } else {
    return timeToCheck >= start && timeToCheck <= end;
  }
};

const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const App = () => {
  const [checkInTime, setCheckInTime] = useState(getCurrentTime());
  const [shift, setShift] = useState("");

  const handleCheckIn = () => {
    const userShift = officeTimings.find(
      (shift) =>
        isTimeWithinRange(checkInTime, shift.from_time, shift.from_late) ||
        isTimeWithinRange(checkInTime, shift.to_time, shift.to_late)
    );

   
    if (userShift) {
      setShift(userShift.shift);
    } else {
      setShift("No shift found");
    }
  };
  

  return (
    <div>
      <h1>Check In</h1>
      <input
        type="time"
        value={checkInTime}
        onChange={(e) => setCheckInTime(e.target.value)}
      />
      <button onClick={handleCheckIn}>Check In</button>
      {shift && <p>You are in the {shift} shift.</p>}
    </div>
  );
};

export default App;
