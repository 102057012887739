export const Login = "/user/signin";
export const Save_location = "/save-location";
export const Pan_Verify = "/user/verify-pan-no";
export const Createcollection = "/api/createcollection";
export const Register = "/api/register";
export const SaveData = "/api/recognize";
export const AttendanceList = "/api/attendanceAws";
export const Recognize = "/api/recognize";
export const SavedData = "/api/localDataAttendance";
export const Attendance = "/attendence/save-attendence";
export const HRM_ATTENDANCE_LIST =
  "https://node-second.rupioo.com/attendance-calculate/"; ///DATABASE

// hrm userlist
export const All_Users_HRM = "/user/user-list-hrm/";
